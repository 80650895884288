<template>
  <div class="section no-pad-bot" id="index-banner">
    <div class="container">
      <br><br>
      <h1 class="header center orange-text">About Site</h1>
      <div class="row center">
        <h5 class="header col s12 light">Site web based on <a  href="http://materializecss.com">Materialize</a> Power By <a  href="https://vuejs.org/">VueJs</a></h5>
        <!--
        <br><br>

        <h5 class="header col s12 light">Source on GitHub  : <a  href="https://github.com/renaudfractale/RPA_Template-RHENRY"> https://github.com/renaudfractale/RPA_Template-RHENRY </a></h5>
        <br><br>
        <h5 class="header col s12 light">to choose : SiteWeb/cli-vuejs3/website </h5>
        -->
      </div>
       </div>
  </div>
 
</template>

<script>
export default {
  name: 'AboutSite',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
