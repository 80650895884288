<template>
  <div class="section no-pad-bot" id="index-banner">
    <div class="container">
      <br /><br />
      <h1 class="header center orange-text">Ou se loger ?</h1>
      <div class="row center">
        <h5 class="header col s12 light">
          Un site pour localiser où se loger sur la ligne TER du travail
        </h5>
      </div>
      <br /><br />
    </div>
  </div>
  <!--
  <div class="container">
    <div class="section">
      <div class="row">
        <div class="col s12 m4">
          Rayon de recherche autour des gares<br />
          <div class="input-field col s12 m6">
            <input v-model="rayon" type="number" max="100000" min="1000" />
            <label for="first_name">Rayon (m)</label>
          </div>
        </div>
        <div class="col s12 m4">
          Prix de la location <br />
          <div class="input-field col s12 m6">
            <input v-model="prixmin" type="number" :max="prixmax" min="0" />
            <label for="prixmin">Prix mini</label>
          </div>
          <div class="input-field col s12 m6">
            <input v-model="prixmax" type="number" max="99999" :min="prixmin"/>
            <label for="prixmax">Prix max</label>
          </div>
        </div>
        <div class="col s12 m4">
          Surface <br />
          <div class="input-field col s12 m6">
            <input v-model="surfmin" type="number" :max="surfmax" min="0" />
            <label for="surfmin">Surface mini(m²)</label>
          </div>
          <div class="input-field col s12 m6">
            <input v-model="surfmax" type="number" max="99999" :min="surfmin"/>
            <label for="surfmax">Surface max (m²)</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
  <div class="container">
    <div class="section">
      <div class="row">
        <div class="col s12 m4">
          Horaire d'arrivé <br />
          <div class="input-field col s12 m6">
            <input v-model="hArriver" type="number" max="23" min="0" />
            <label for="first_name">Heure D'arrivé</label>
          </div>
          <div class="input-field col s12 m6">
            <input v-model="mArriver" type="number" max="59" min="0" />
            <label for="first_name">Mnute D'arrivé</label>
          </div>

          <div class="input-field col s12 m12">
            <input v-model="dArriver" type="number" max="59" min="1" />
            <label for="first_name">+/- Minutes </label>
          </div>
        </div>
        <div class="col s12 m4">
          Horaire de depart <br />
          <div class="input-field col s12 m6">
            <input v-model="hDepart" type="number" max="23" min="0" />
            <label for="hDepart">Heure de Départ</label>
          </div>
          <div class="input-field col s12 m6">
            <input v-model="mDepart" type="number" max="59" min="0" />
            <label for="mDepart">Mnute de Départ</label>
          </div>
          <div class="input-field col s12 m12">
            <input v-model="dDepart" type="number" max="59" min="1" />
            <label for="dDepart">+/- Minutes </label>
          </div>
        </div>
        <div class="col s12 m4">
          Temp de trajet <br />
          <div class="input-field col s12 m12">
            <input v-model="tTrajet" type="number" max="120" min="1" />
            <label for="tTrajet">Temps de trajet (min) </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="section">
      <div class="row">
        <div class="col s12 m4">
          <h5 class="center orange-text">Recherche Par Gare Cible</h5>
          <div class="input-field">
            <input
              placeholder="Nom de la gare"
              v-model="gareCible"
              type="text"
            />
            <label for="first_name">Gare Cible</label>
            <a
              class="btn-large waves-effect waves-light orange col s12"
              @click="ComputeNameGare"
              href="#Gares"
              >Rechercher la gare</a
            >
          </div>
        </div>

        <div class="col s12 m4">
          <h5 class="center orange-text">Recherche Par Position GPS Cible</h5>
          <div class="input-field">
            <input v-model="latCibleInput" type="text" />
            <label for="first_name">Latitute Cible</label>
          </div>
          <div class="input-field">
            <input v-model="lonCibleInput" type="text" />
            <label for="first_name">Longitude Cible</label>
          </div>
          <a
            class="btn-large waves-effect waves-light orange col s12"
            @click="ComputeGPSPInput"
            href="#Gares"
            >Rechercher une gare à proximité</a
          >
        </div>

        <div class="col s12 m4">
          <h5 class="center orange-text">Recherche Par ma Position GPS</h5>
          <a
            class="btn-large waves-effect waves-light orange col s12"
            @click="GetPoseGPS"
            >Détecter ma Position</a
          >
          <div v-if="currentPosGPS" class="center">
            Lat : {{ latCibleProxy }} <br />
            Lon : {{ lonCibleProxy }} <br />
            <a
              class="btn-large waves-effect waves-light orange col s12"
              @click="ComputeGPSProxy"
              href="#Gares"
              >Rechercher une gare à proximité</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="section">
      <div class="row">
        <ul class="collection" v-if="!listeGaresCible_error.error">
          <li
            v-for="(value, index) in listeGaresCible_data"
            class="collection-item"
            :key="'ville' + index"
            href="#Resultat"
          >
            Distance : {{ value.distance }} Km --- Gare : {{ value.stopname }}
            <br />
            Latitute : {{ value.stoplat }} --- Longitude : {{ value.stoplon }}
            <!-- UlrMaps-->
            <br />
            <a
              class="btn-large waves-effect waves-light orange col s12"
              :href="UrlMaps(value.stoplat, value.stoplon)"
              target="_blank"
              >Afficher sur une carte</a
            >
            <a
              class="btn-large waves-effect waves-light orange col s12"
              @click="FindGares(value.stopid)"
              href="#Resultat"
              >Rechercher les Gares TER Liées</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <a name="Gares"></a>
  <div class="container" v-if="rechercheEnCour > 0">
    <div class="section">
      <div class="row">
        <div class="progress" v-if="rechercheEnCour == 1">
          <div class="indeterminate"></div>
        </div>

        <ul class="collection" v-if="rechercheEnCour == 2">
          <li class="collection-item">
            Il y a {{ listeGaresFound_data.length }} Gare(s) trouvée(s)
          </li>
          <li
            class="collection-item"
            v-for="(value, index) in listeGaresFound_data"
            :key="'R' + index"
          >
            Gare trouvée : {{ value.info.stopname }} --- Latitute :
            {{ value.info.stoplat }} --- Longitude : {{ value.info.stoplon
            }}<br />
            Trajet Lundi : {{ value.semaineAller[0] }} /
            {{ value.semaineRetour[0] }} <br />
            Trajet Mardi : {{ value.semaineAller[1] }} /
            {{ value.semaineRetour[1] }} <br />
            Trajet Mercredi : {{ value.semaineAller[2] }} /
            {{ value.semaineRetour[2] }} <br />
            Trajet Jeudi : {{ value.semaineAller[3] }} /
            {{ value.semaineRetour[3] }} <br />
            Trajet Vendredi : {{ value.semaineAller[4] }} /
            {{ value.semaineRetour[4] }} <br />
            Trajet Samedi : {{ value.semaineAller[5] }} /
            {{ value.semaineRetour[5] }} <br />
            Trajet Dimanche : {{ value.semaineAller[6] }} /
            {{ value.semaineRetour[6] }}
            <!--
            <a
              class="btn-large waves-effect waves-light orange col s12"
              :href="UrlBC(value.info.stopname,value.info.stoplat, value.info.stoplon)"
               target="_blank"
              >Afficher sur le boncoin</a
            >-->
          </li>
        </ul>
        <div v-if="rechercheEnCour == 3">
          Erreur duran l'analyse de la demande
        </div>
      </div>
    </div>
  </div>
  <a name="Resultat"></a>
</template>

<script>
import { Geolocation } from "@capacitor/geolocation";
export default {
  name: "Home",
  data() {
    return {
      gareCible: "",
      latCibleInput: "",
      lonCibleInput: "",
      latCibleProxy: 0,
      lonCibleProxy: 0,
      currentPosGPS: null,
      listeGaresCible_error: {
        error: false,
        messageRaw: "",
        messageClient: "",
      },
      listeGaresCible_data: [],
      rechercheEnCour: 0,
      listeGaresFound_data: [],
      dArriver: 15,
      hArriver: 8,
      mArriver: 0,
      dDepart: 15,
      hDepart: 18,
      mDepart: 0,
      tTrajet: 50,
      rayon: 1000,
      surfmin: 10,
      surfmax: 100,
      prixmin: 10,
      prixmax: 1000,
    };
  },
  //mixins: [Geolocation],
  methods: {
    UrlMaps(lat, lon) {
      return "https://www.google.com/search?q=maps+" + lat + "+" + lon;
    },
    /*
    UrlBC(gare,lat,lon){ //{{gare}}_00000
      let urlraw = "https://www.leboncoin.fr/recherche?category=10&locations=__{{lat}}_{{lon}}_{{rayon}}&real_estate_type=1%2C2&price={{prixmin}}-{{prixmax}}&square={{surfmin}}-{{surfmax}}"
      urlraw = urlraw.replace("{{gare}}",gare)
      urlraw = urlraw.replace("{{lat}}",lat)
      urlraw = urlraw.replace("{{lon}}",lon)
      urlraw = urlraw.replace("{{rayon}}",this.rayon)
      urlraw = urlraw.replace("{{prixmin}}",this.prixmin)
      urlraw = urlraw.replace("{{prixmax}}",this.prixmax)
      urlraw = urlraw.replace("{{surfmin}}",this.surfmin)
      urlraw = urlraw.replace("{{surfmax}}",this.surfmax)
      return  urlraw
      //https://www.leboncoin.fr/recherche?category=10&locations=Lyon__45.74578907434404_4.853361113809419_5875&real_estate_type=1%2C2&price=111-2222&square=10-1000
    },*/
    async FindGares(id) {
      this.rechercheEnCour = 1;
      console.log(id);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var body = {
        id: id,
        timeStart: this.hArriver * 60 + this.mArriver,
        timeEnd: this.hDepart * 60 + this.mDepart,
        deltaStart: this.dArriver,
        deltaEnd: this.dDepart,
        trajetDuration: this.tTrajet,
        token: "azerty",
      };
      let rawGetTokent = JSON.stringify({
        url: "/findgares",
        methode: "POST",
        head: ["Content-Type: application/json"],
        body,
      });

      let requestOptions = {
        method: "get",
        headers: {},
        redirect: "follow",
      };
      //console.log("/form.php?rq=" + rawGetTokent)
      const request = await fetch(
        "/form.php?rq=" + rawGetTokent,
        requestOptions
      );
      /*
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      let request = await fetch("/findgares", requestOptions);
    */
      try {
        let reponse = await request.json();

        for (let index = 0; index < reponse.length; index++) {
          const element = reponse[index];

          let semaineAller = [0, 0, 0, 0, 0, 0, 0];
          element.timeAller.forEach((dateString) => {
            semaineAller[new Date(dateString).getDay() - 1]++;
          });
          reponse[index].semaineAller = semaineAller;

          let semaineRetour = [0, 0, 0, 0, 0, 0, 0];
          element.timeRetour.forEach((dateString) => {
            semaineRetour[new Date(dateString).getDay() - 1]++;
          });
          reponse[index].semaineRetour = semaineRetour;
        }
        console.log(reponse);
        this.listeGaresFound_data = reponse;
        this.rechercheEnCour = 2;
      } catch (error) {
        console.log(error);
        this.rechercheEnCour = 3;
      }
    },
    async ComputeNameGare() {
      this.rechercheEnCour = 0;
      if (this.gareCible.length == 0) {
        this.Empty_listeGaresCible();
        return;
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var body = {
        ville: this.gareCible,
        token: "azerty",
      };

      let rawGetTokent = JSON.stringify({
        url: "/findgareciblebyname",
        methode: "POST",
        head: ["Content-Type: application/json"],
        body,
      });

      let requestOptions = {
        method: "get",
        headers: {},
        redirect: "follow",
      };
      //console.log("/form.php?rq=" + rawGetTokent)
      const request = await fetch(
        "/form.php?rq=" + rawGetTokent,
        requestOptions
      );

      /*   New Requette à faire
      let rawGetTokent = JSON.stringify({
        url: "https://account.uipath.com/oauth/token",
        methode: "POST",
        head: [
          "X-UIPATH-TenantName : " + this.tenantName,
          "Content-Type: application/json",
        ],
        body: {
          grant_type: "refresh_token",
          client_id: this.clientId,
          refresh_token: this.userKey,
        },
      });

      let requestOptions = {
        method: "get",
        headers: {},
        redirect: "follow",
      };
      const responseToken = await fetch(
        "/form.php?rq=" + rawGetTokent,
        requestOptions
      );
      
      */

      /*
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let request = await fetch("/findgareciblebyname", requestOptions);*/

      try {
        let reponse = await request.json();
        console.log(reponse);
        if (Array.isArray(reponse)) {
          this.listeGaresCible_error = {
            error: false,
            messageRaw: "",
            messageClient: "",
          };
          this.listeGaresCible_data = reponse;

          console.log(this.listeGaresCible_error);
        } else {
          this.listeGaresCible_error = {
            error: true,
            messageRaw: reponse,
            messageClient: "Erreur : le type de retour n'est pas un tableau",
          };
          this.listeGaresCible_data = [];
        }
      } catch (error) {
        this.listeGaresCible_error = {
          error: true,
          messageRaw: error,
          messageClient: "Erreur : Mauvaise requête",
        };
        this.listeGaresCible_data = [];
      }
    },
    Empty_listeGaresCible() {
      this.listeGaresCible_error = {
        error: false,
        messageRaw: "",
        messageClient: "",
      };
      this.listeGaresCible_data = [];
    },
    async GetPoseGPS() {
      this.currentPosGPS = await Geolocation.getCurrentPosition();
      console.log("Current position:", this.currentPosGPS);
      this.latCibleProxy = this.currentPosGPS.coords.latitude;
      this.lonCibleProxy = this.currentPosGPS.coords.longitude;
    },
    async ComputeGPSProxy() {
      await this.GetListeGareByPosGPS(this.latCibleProxy, this.lonCibleProxy);
    },
    async ComputeGPSPInput() {
      this.rechercheEnCour = 0;
      let txtlat = this.latCibleInput.replace(",", ".");
      let txtlon = this.lonCibleInput.replace(",", ".");
      //console.log(this.latCibleInput,this.lonCibleInput)
      if (txtlat.length == 0 && txtlon.length == 0) {
        this.Empty_listeGaresCible();
        console.log(this.listeGaresCible);
        return;
      }
      try {
        let nblat = parseFloat(txtlat);
        let nblon = parseFloat(txtlon);
        //console.log(nblat,nblon)
        if (nblat.toString() != "Nan" && nblon.toString() != "Nan") {
          await this.GetListeGareByPosGPS(nblat, nblon);
          return;
        } else {
          this.Empty_listeGaresCible();
          return;
        }
      } catch (error) {
        this.Empty_listeGaresCible();
        return;
      }
    },
    async GetListeGareByPosGPS(lat, lon) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var body = {
        lat: lat,
        lon: lon,
        token: "azerty",
      };
      /*
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let request = await fetch("/findgareciblebypos", requestOptions);
*/
      let rawGetTokent = JSON.stringify({
        url: "/findgareciblebypos",
        methode: "POST",
        head: ["Content-Type: application/json"],
        body,
      });

      let requestOptions = {
        method: "get",
        headers: {},
        redirect: "follow",
      };
      //console.log("/form.php?rq=" + rawGetTokent)
      const request = await fetch(
        "/form.php?rq=" + rawGetTokent,
        requestOptions
      );

      try {
        let reponse = await request.json();
        console.log(reponse);
        if (Array.isArray(reponse)) {
          this.listeGaresCible_error = {
            error: false,
            messageRaw: "",
            messageClient: "",
          };
          this.listeGaresCible_data = reponse;
        } else {
          this.listeGaresCible_error = {
            error: true,
            messageRaw: reponse,
            messageClient: "Erreur : le type de retour n'est pas un tableau",
          };
          this.listeGaresCible_data = [];
        }
      } catch (error) {
        this.listeGaresCible_error = {
          error: true,
          messageRaw: error,
          messageClient: "Erreur : Mauvaise requête",
        };
        this.listeGaresCible_data = [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
