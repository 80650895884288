<template>
  <nav class="light-blue lighten-1" role="navigation">
    <div class="nav-wrapper container">
      <img src="./assets/logo.png" style="padding: 5px" class="brand-logo" />
      <ul class="right hide-on-med-and-down">
        <li>
          <router-link :to="{ name: 'Home' }">
            Moteur de recherche
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AboutDev' }">A propos du Développeur</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AboutSite' }"> A propos du Site </router-link>
        </li>
      </ul>

      <ul id="nav-mobile" class="sidenav">
        <li>
          <router-link :to="{ name: 'Home' }">  Moteur de recherche</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AboutDev' }">A propos du Développeur</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AboutSite' }"> A propos du Site </router-link>
        </li>
      </ul>
      <a data-target="nav-mobile" class="sidenav-trigger"
        ><i class="material-icons">menu</i></a
      >
    </div>
  </nav>
  <router-view ></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  methods: {
    switchlang(lang) {
      this.currentLocale = lang;
    },
  },
};
</script>

<style>
</style>
